export const topProductsData = [
    {
        title:'ORS drink for Diabetic & Hypertensive People',
        image: require("../assets/restl.jpg")
    },
    {
        title:'ORS drink in tetra pack in 2 flavours(Apple & Orange)',
        image: require("../assets/dbrestl.jpg")
    },
    {
        title:'Digestive Enzyme Syrup',
        image: require("../assets/mbpepti.jpg")
    },
]