export const machineriesData = [
    {
        title: 'Machine for ORS drink',
        image: require("../assets/Orsmachine.jpeg"),
        
      },
      {
        title: 'Protein Powder Machineries',
        image: require("../assets/proteinpowder.jpg"),
       
      },
      {
        title: 'Blunder for fine powder',
        image: require("../assets/finepowdee.jpg"),
        
      },
      {
        title: 'Machine for ORS drink',
        image:require("../assets/tetrapack.jpg"),
        
      },
      {
        title: 'Liquid Oral Machine',
        image:require("../assets/sugarsyrup.jpg"),
        
      },

       {
        title: 'Sterilizer',
        image: require("../assets/Sterlizer.jpg"),
       
      },
      {
        title: 'Boiler',
        image: require("../assets/Boiler.jpg"),
        
      },
      {
        title: 'Heater',
        image:require("../assets/Heater.jpg"),
        
      },
      {
        title: 'Sterilizer',
        image:require("../assets/Sterlizier1.jpg"),
        
      },
]